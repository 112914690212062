<!-- @format -->

<template>
  <div class="flex flex-col gap-8">
    <div class="flex justify-between items-center">
      <div>
        <div class="text-lg font-medium">
          Patient Survey {{ survey.month }}, {{ survey.year }}
        </div>
        <small class="text-red-500" v-if="!isSurveyActive"
          >This survey is currently inactive</small
        >
      </div>
      <div class="text-teal-light flex gap-4">
        <div
          class="flex justify-between border-teal border hover:bg-teal hover:text-white px-2 p-1 rounded-md cursor-pointer"
          @click="navigate()"
        >
          Preview
          <i class="material-icons w-5">preview_outlined</i>
        </div>
        <div
          v-if="isSurveyActive"
          class="flex justify-between px-2 border-teal border hover:bg-teal hover:text-white p-1 bg-opacity-10 rounded-md cursor-pointer"
          @click="downloadSurveyPDF()"
        >
          Download PDF
          <i class="material-icons w-5">download_outlined</i>
        </div>
      </div>
    </div>
    <div class="bg-white shadow-md rounded-md border border-grey-700">
      <div class="p-6">
        <span class="text-lg">List of Practices</span>
      </div>
      <div
        class="h-12 px-6 text-white flex justify-between items-center"
        :class="isSurveyActive ? 'bg-teal' : 'bg-gray-500'"
      >
        <span class="text-sm">List</span>
        <span class="text-sm mr-8" v-if="isSurveyActive">Copy Link</span>
      </div>
      <ul class="px-6">
        <li
          v-for="instance in survey.survey_instances"
          :key="instance.id"
          class="border-b border-textGray-400 py-6"
        >
          <div class="flex justify-between items-center">
            <div class="flex flex-col gap-4">
              <div class="flex">
                <span
                  class="material-icons mt-4 text-4xl"
                  :class="isSurveyActive ? 'text-red' : 'text-gray-500'"
                  >insert_drive_file</span
                >

                <div class="ml-4">
                  <p class="font-medium mt-3">
                    {{ instance.practice.practice_name }} {{ survey.month }},
                    {{ survey.year }}
                  </p>
                  <p class="text-grey underline">{{ instance.link }}</p>
                </div>
              </div>
            </div>
            <button
              v-if="isSurveyActive"
              @click="copyLink(instance, idx)"
              class="text-teal text-md"
            >
              <span class="w-6 flex items-center gap-2 mr-16"
                >Copy
                <span class="material-icons text-sm">{{
                  instanceId === instance.id ? "done" : "content_copy"
                }}</span></span
              >
            </button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { onMounted, computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Swal from "sweetalert2";
import axios from "axios";

const store = useStore();
const router = useRouter();
const route = useRoute();
const instanceId = ref(null);
const isSurveyActive = parseInt(route.params.isActive);
const id = route.params.id;
const domainName = window.location.origin;

onMounted(() => {
  store.dispatch("patientSurveys/fetchSingleSurvey", id);
});

const survey = computed(() => {
  let singleSurvey = store.getters["patientSurveys/getSingleSurvey"];
  singleSurvey?.survey_instances?.forEach((instance) => {
    instance.link = `${domainName}/survey-submission/practice/${instance.practice.id}/survey/${singleSurvey.id}/instance/${instance.id}`;
  });
  return singleSurvey;
});
const navigate = () => {
  router.push({ name: "hq-survey-questions-preview" });
};
const downloadSurveyPDF = async () => {
  const halfURL = axios.defaults.baseURL;
  const url = `${halfURL}/hq/patient-surveys/generate-pdf?token=${localStorage.getItem(
    "token"
  )}`;
  window.open(url, "_blank");
};

const copyLink = (instance) => {
  navigator.clipboard.writeText(instance.link).then(() => {
    instanceId.value = instance.id;
    Swal.fire({
      toast: true,
      position: "bottom-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseleave = Swal.resumeTimer;
      },
      didClose: () => {
        instanceId.value = null;
      },
      title: "Done",
      text: "Link Copied",
      icon: "success",
    });
  });
};
</script>
